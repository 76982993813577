import React from 'react';
import ReactDOM from 'react-dom';
import ReactDOMClient from 'react-dom/client';
import { ToolTipWidget } from './ToolTip';



//need tooltip div

const pageBody: HTMLCollectionOf<Element> = document.getElementsByTagName('body') as HTMLCollectionOf<Element>;
if (pageBody && pageBody.length > 0) {
    const toolTipPortal = () => {
        const toolTipElement: HTMLCollectionOf<Element> = document.getElementsByClassName('rebateType_Tooltip_react') as HTMLCollectionOf<Element>;
        const portals: React.ReactPortal[] = [];
        for (let i = 0; i < toolTipElement.length; i++) {
            const rebateType: string | null = toolTipElement[i].getAttribute("data-text");
            if (rebateType) {
                const root = ReactDOM.createPortal(<ToolTipWidget incentiveType={rebateType} />, toolTipElement[i]);
                portals.push(root);

            }
        }
        return portals;
    }


    const newNode = pageBody[0].appendChild(document.createElement("div"));
    const root = ReactDOMClient.createRoot(newNode);

    root.render(
        <React.StrictMode>
            <>
                {toolTipPortal().map((portal) => portal)}
            </>
        </React.StrictMode>
    );



}